require('@rails/ujs').start()
require('turbolinks').start()
require('inter-ui')

import './../web-player.js'

import './youtube-embed-for-sites.js'

import '@fortawesome/fontawesome-free/css/all';

import './../stylesheets/tailwind'
import './../stylesheets/choices'
import './../stylesheets/choices_overrides'
import './../stylesheets/custom'

import 'alpinejs'

const images = require.context('../../images', true)
const imagePath = (name) => images(name, true)

import lozad from 'lozad';

document.addEventListener("turbolinks:load", function() {
  const observer = lozad();
  observer.observe();

  // Toggle for transcript box
  if(document.getElementById('transcription-box-toggle')) {
    const toggle = document.getElementById('transcription-box-toggle');
    const transcriptionBox = document.getElementById('transcription-box');
    const transcriptionOverlay = document.getElementById('transcription-overlay');

    toggle.addEventListener('click', () => {

      setTimeout(() => {
        transcriptionBox.style.maxHeight = '999999rem';
        transcriptionBox.style.height = 'auto';
        transcriptionOverlay.remove();
      }, 250);

      transcriptionOverlay.classList.add('opacity-0');
    });
  }
});
