import Cookies from 'js-cookie';

document.addEventListener('turbolinks:load', () => {
  if (document.getElementsByClassName('youtube')) {
    let youtubeElements = document.getElementsByClassName('youtube');

    for (let i = 0; i < youtubeElements.length; i++) {
      let element = youtubeElements[i];
      let youtubeId = element.dataset.youtubeId;
      let thumbnail = element.dataset.thumbnail;
      let podcastIdent = element.dataset.podcastIdent;
      let privacyUrl = element.dataset.privacyUrl;
      let consentText = element.dataset.consentText;
      let privacyProtection = element.dataset.privacyProtection;
      let loadVideo = element.dataset.loadVideo;

      if (get_cookie(podcastIdent) === 'true') {
        loadPlayer(element, youtubeId);
      } else {
        loadConsentBox(element, youtubeId, thumbnail, podcastIdent, privacyUrl, consentText, privacyProtection, loadVideo);
      }
    }
  }

  function loadPlayer(element, youtubeId) {
    element.innerHTML = `<iframe
                          src="https://www.youtube-nocookie.com/embed/${youtubeId}"
                          frameborder="0"
                          class="rounded-lg mx-auto z-50 w-full h-56 md:h-80 lg:h-full"
                          allow="autoplay"
                          style="max-height: 480px;"
                          allowfullscreen>
                         </iframe>`
  }

  function loadConsentBox(element, youtubeId, thumbnail, podcastIdent, privacyUrl, consentText, privacyProtection, loadVideo) {
    element.innerHTML = `
      <div class="relative mx-auto w-full rounded-lg shadow-lg">
        <div class="text-center relative block w-full h-56 md:h-80 lg:h-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
          <img class="w-full h-full object-cover object-center" alt="LetsCast.fm YouTube Consent Thumbnail" src="${thumbnail}" loading="lazy" style="filter: blur(0.25rem);" />
          <div class="absolute inset-0 w-full h-full flex items-center justify-center opacity-75" style="background-color: #000000D9;"></div>
          <div class="absolute inset-0 w-full h-full flex items-center justify-center">
            <div class="flex flex-col">
              <p class="text-white my-4 px-2">
                ${consentText} <a href="${privacyUrl == undefined ? '' : privacyUrl}" target="_blank" class="underline">${privacyProtection}</a>.
              </p>
              <button class="consent-button bg-teal-400 px-4 py-2 mb-4 text-white rounded font-medium w-32 mx-auto">
                ${loadVideo}
              </button>
            </div>
          </div>
        </div>
      </div>
    `

    let consentButton = element.getElementsByClassName('consent-button')[0];

    consentButton.addEventListener('click', function () {
      loadPlayer(element, youtubeId);
      set_cookie(podcastIdent, true);
    });
  }

  function get_cookie(podcastIdent) {
    return Cookies.get(`youtube_consent_${podcastIdent}`);
  };

  function set_cookie(podcastIdent, param) {
    Cookies.set(`youtube_consent_${podcastIdent}`, param, {
      path: '/',
      expires: 90
    });
  }
});
